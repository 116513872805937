<template>
  <base-table
    v-bind="$attrs"
    :cols="cols"
    :rows="rows"
    :sort-field="sortBy"
    :order="sortOrder"
    :lazy="true"
    @row-click="rowClick"
    @sort="sort"
  >
    <template #register_at="{ data }">
      {{ formatDate(data.register_at) }}
    </template>
    <template #account="{ data }">
      <div>
        <router-link
          v-if="isDesktop"
          class="link"
          :to="`/users/players/${data.id}`"
        >
          {{ data.account }}
        </router-link>
        <span v-else>{{ data.account }}</span>
        <div class="text-sm color-zinc-400">
          {{ data.real_name }}
        </div>
      </div>
    </template>
    <template #deposit_cash="{ data }">
      <base-cash :cash="data.deposit_cash" />
    </template>
    <template #revenue="{ data }">
      <base-cash :cash="data.revenue" />
    </template>
    <template #cash="{ data }">
      <base-cash :cash="data.cash" />
    </template>
    <template #status="{ data }">
      <base-status
        :status="data.status"
        :color-map="PlayerStatusColor"
        :name-map="PlayerStatusName"
      />
    </template>
    <template #last_login_at="{ data }">
      {{ formatDate(data.last_login_at) }}
    </template>
    <template #memo="{ data }">
      <base-setable-data
        :id="data.id"
        :data="data.memo"
        :dialog="BaseMemoDialog"
        :callback="updateMemo"
      />
    </template>
  </base-table>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { formatDate } from '@/utils/format'
import BaseMemoDialog from '@/components/base/BaseMemoDialog.vue'
import { PlayerStatusColor, PlayerStatusName } from '@/constants/StatusMapping'
import { PlayersRes, PlayerStatusType } from '@golden/gdk-agent-next'
import { DataTableRowClickEvent, DataTableSortEvent } from 'primevue/datatable'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'
import gdk from '@/gdk'
import { lastValueFrom } from 'rxjs'
import { TableColType } from '@/utils/TableHelper'
import { useGames } from '@/functions/useGames'

export default defineComponent({
  name: 'PlayersTable',
  props: {
    rows: {
      type: Array as PropType<Array<PlayersRes<Date>>>,
      default: () => ([])
    },
    sortBy: {
      type: String,
      default: ''
    },
    order: {
      type: String as PropType<'asc' | 'desc'>,
      default: 'asc'
    }
  },
  emits: ['update', 'update:sortBy', 'update:order'],
  setup (props, context) {
    const { t } = useI18n()
    const router = useRouter()
    const { isDesktop, isMobile } = storeToRefs(useMediaQueryStore())
    const { mainWalletName } = useGames()
    const cols = computed<TableColType[]>(() => [
      {
        field: 'staff',
        header: t('common.directStaff'),
        'body-style': { minWidth: '120px', height: '56px' },
        'header-style': { minWidth: '120px' }
      },
      {
        field: 'account',
        header: t('common.account'),
        'body-style': { minWidth: '120px', height: '56px' },
        'header-style': { minWidth: '120px' }
      },
      {
        field: 'register_at',
        header: t('common.registerDate'),
        sortable: true,
        align: 'center'
      },
      {
        field: 'deposit_cash',
        header: t('common.sumDeposit'),
        sortable: true,
        align: 'end',
        'body-style': { paddingRight: '0' },
        'header-style': { paddingRight: '0' }
      },
      {
        field: 'deposit_count',
        header: t('common.sumDepositCount'),
        sortable: true,
        align: 'end',
        'body-style': { paddingRight: '0' },
        'header-style': { paddingRight: '0' }
      },
      {
        field: 'revenue',
        header: t('common.total', { item: t('common.revenue') }),
        align: 'end',
        'body-style': { paddingRight: '0' },
        'header-style': { paddingRight: '0' }
      },
      {
        field: 'cash',
        header: mainWalletName,
        align: 'end'
      },
      {
        field: 'status',
        header: t('common.status'),
        align: 'center'
      },
      {
        field: 'last_login_at',
        header: t('common.lastLoginAt'),
        sortable: true,
        align: 'center'
      },
      {
        field: 'memo',
        header: t('common.memo')
      }
    ])

    const rowClick = (event: DataTableRowClickEvent) => {
      if (isMobile.value) {
        void router.push(`/users/players/${(event.data as { id: string }).id}`)
      }
    }

    // Prime Table 時間 sort 顯示上和規格相反，因此需要另外做處理
    const parseSortOrder = (order: 'asc' | 'desc') => {
      if (props.sortBy === 'register_at' || props.sortBy === 'last_login_at') {
        if (order === 'asc') return 'desc'
        if (order === 'desc') return 'asc'
      }
      return order
    }

    const sortOrder = computed(() => parseSortOrder(props.order))

    const sort = (event: DataTableSortEvent) => {
      context.emit('update:sortBy', event.sortField)
      context.emit('update:order', parseSortOrder(event.sortOrder === 1 ? 'asc' : 'desc'))
    }

    const updateMemo = async (payload: { id: number, data: string }) => {
      return await lastValueFrom(gdk.user.updatePlayersMemo({ memo: payload.data }, payload.id)).then(() => {
        context.emit('update')
      })
    }

    return {
      BaseMemoDialog,
      cols,
      sortOrder,
      PlayerStatusName,
      PlayerStatusColor,
      isDesktop,
      PlayerStatusType,
      t,
      rowClick,
      sort,
      formatDate,
      updateMemo
    }
  }
})
</script>
