<template>
  <prime-data-table
    ref="table"
    responsive-layout="scroll"
    :value="value"
    :sort-field="sortByModel"
    :sort-order="sortOrder"
    :class="{ 'sticky-table': isMobile }"
    scrollable
    @sort="sort"
    @row-click="rowClick"
  >
    <template #loading>
      {{ t('common.isSearching') }}
    </template>
    <template
      v-for="col of cols"
      :key="`column-${col.field}`"
    >
      <prime-column
        :field="col.field"
        :header="col.header"
        :style="colStyle(col)"
        :header-style="col['header-style']"
        :body-style="col['body-style']"
        :sortable="col.sortable"
      >
        <template
          v-if="isFirstLoad"
          #body
        >
          <prime-skeleton />
        </template>
        <template
          v-else-if="$slots[col.field]"
          #body="slotProps"
        >
          <slot
            :name="col.field"
            v-bind="slotProps"
          />
        </template>
      </prime-column>
    </template>
    <template
      v-if="showFooter && rows.length >= 25 || (loading && !isFirstLoad)"
      #footer
    >
      <div class="text-center h-20px text-3.5 lh-normal color-[var(--text-color-secondary)] fw-normal">
        {{ footerTip }}
      </div>
    </template>
    <template #empty>
      {{ t('common.noData') }}
    </template>
  </prime-data-table>
</template>

<script setup lang="ts">
import { defineOptions, ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import type { DataTableSortEvent, DataTableRowClickEvent } from 'primevue/datatable'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'
import { useScrollTip } from '@/functions/useScrollTip'
import { fromEvent, tap } from 'rxjs'
import { useObservable } from '@golden/shared-vue'
import type { TableColType } from '@/utils/TableHelper'
import { TableOrderType } from '@/utils/TableHelper'

const skeletonRows = [{}]

defineOptions({
  name: 'BaseTable'
})

const props = withDefaults(defineProps<{
  cols?: TableColType[],
  rows?: Array<Record<string, any>>,
  loading?: boolean,
  isFirstLoad?: boolean,
  finished?: boolean,
  perPage?: number,
  showFooter?: boolean
}>(), {
  cols: () => ([]),
  rows: () => ([]),
  loading: false,
  isFirstLoad: false,
  finished: false,
  perPage: 25,
  showFooter: true
})

const emit = defineEmits(['row-click', 'scroll'])

const sortByModel = defineModel<string>('sortBy', { default: '' })
const orderModel = defineModel<TableOrderType>('order', { default: 'asc' })

const { isMobile } = storeToRefs(useMediaQueryStore())
const { t } = useI18n()
const table = ref<{ $el: HTMLElement }>()
const tableWrapper = ref<HTMLElement | null>(null)
const { footerTip } = useScrollTip({
  rowsLength: computed(() => props.rows.length),
  perPage: computed(() => props.perPage),
  isFirstLoad: computed(() => props.isFirstLoad),
  finished: computed(() => props.finished),
  loading: computed(() => props.loading)
})

const value = computed(() => props.isFirstLoad ? skeletonRows : props.rows)

const defaultColumStyle = computed(() => {
  const minWidth = isMobile.value ? '100px' : '120px'
  return { minWidth }
})
const sortOrder = computed(() => orderModel.value === 'asc' ? 1 : -1)
const sort = (event: DataTableSortEvent) => {
  sortByModel.value = event.sortField as string
  orderModel.value = event.sortOrder === 1 ? 'asc' : 'desc'
}

const rowClick = (event: DataTableRowClickEvent) => {
  emit('row-click', event)
}

const colStyle = (col: typeof props['cols'][number]) => {
  let align: { justifyContent?: string, textAlign?: string } = {}

  if (col.align === 'start') align = { justifyContent: 'flex-start', textAlign: 'left' }
  if (col.align === 'center') align = { justifyContent: 'center', textAlign: 'center' }
  if (col.align === 'end') align = { justifyContent: 'flex-end', textAlign: 'right' }

  return { ...defaultColumStyle, ...align, ...col.style }
}

onMounted(() => {
  tableWrapper.value = table.value?.$el.querySelector('.p-datatable-wrapper') as HTMLElement
  const thead = ref(table.value?.$el.querySelector('thead'))
  const tbody = ref(table.value?.$el.querySelector('tbody'))
  if (thead.value && tbody.value) {
    useObservable(fromEvent(tbody.value, 'scroll').pipe(tap(() => {
      (thead.value as HTMLElement).scrollLeft = tbody.value?.scrollLeft as number
    })))
  }
})
</script>

<style lang="scss" scoped>
.p-datatable {
  :deep(.p-datatable-wrapper) {
    @apply overflow-unset bg-white;
  }
  :deep(table) {
    @apply block w-100%;
  }
  :deep(.p-datatable-thead) {
    @apply block w-100% overflow-hidden sticky top--4 rd-t-6px important-bg-#EEEEEE;
    background-image:
      linear-gradient(to left, #EEEEEE, transparent),
      linear-gradient(to right, #EEEEEE, transparent),
      linear-gradient(to right, #ccc, #eee 20%, transparent 50%),
      linear-gradient(to left, #ccc, #eee 20%, transparent 50%);
    background-position:
      right,
      left,
      left,
      right;
    background-repeat: no-repeat;
    background-size:
      5rem 100%,
      5rem 100%,
      1.5rem 100%,
      1.5rem 100%;
    background-attachment:
      local,
      local,
      scroll,
      scroll;
  }
  :deep(.p-datatable-tbody) {
    @apply block w-100% overflow-auto;
    background-image:
      linear-gradient(to left, #fff, transparent),
      linear-gradient(to right, #fff, transparent),
      linear-gradient(to right, #ccc, #eee 20%, transparent 50%),
      linear-gradient(to left, #ccc, #eee 20%, transparent 50%);
    background-position:
      right,
      left,
      left,
      right;
    background-repeat: no-repeat;
    background-size:
      5rem 100%,
      5rem 100%,
      1.5rem 100%,
      1.5rem 100%;
    background-attachment:
      local,
      local,
      scroll,
      scroll;
  }
  :deep(.p-datatable-tbody > tr) {
    @apply bg-transparent;
  }
  :deep(.p-datatable-footer) {
    @apply bg-white rd-b-6px;
  }
  :deep(.p-datatable-thead > tr > th) {
    @apply px-12px py-8px lh-normal bg-transparent h-41px;
    &.p-sortable-column.p-highlight {
      @apply bg-#EFF6FF;
    }
  }
  :deep(.p-datatable-tbody > tr > td) {
    @apply px-12px py-8px lh-normal;
  }
  :deep(.pi) {
    &.pi-sort-alt {
      @apply i-ri-arrow-up-down-fill;
    }
    &.pi-sort-amount-up-alt {
      @apply i-ri-sort-asc;
    }
    &.pi-sort-amount-down {
      @apply i-ri-sort-desc;
    }
  }
  :deep(.pi.pi-sort-amount-up-alt) {
    @apply ml-7px inline-block w-16px h-16px;
  }
  :deep(.p-column-title) {
    @apply whitespace-nowrap;
  }
}
</style>
